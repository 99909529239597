import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styles from './productpage.module.css'
import { ButtonStyle } from "../UIElements/Buttons/ButtonStyle";

const OrderList = (props) => {
    const { id } = useParams(); 
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderStatus, setOrderStatus] = useState("");
    const [orderId, setOrderId] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchOrders()
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/order/mart/${id}`);
            if (response.status === 200) {
                setOrders(response.data);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedOrder) {
            setOrderStatus(selectedOrder.orderStatus);
            setOrderId(selectedOrder.id)
            setIsDisabled(selectedOrder.orderStatus === "PENDING");
        }
    }, [selectedOrder, orderStatus]);

    const handleStatusChange = async (newStatus) => {
        // Update state immediately
        setOrderStatus(newStatus);

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/order/${orderId}/status?status=${newStatus}`
            );

            if (response.status === 200) {
                setOrderStatus(response.data.status || newStatus); // Ensure the status is set correctly
                setIsDisabled(response.data.status === "PENDING");

                // Update selectedOrder with new status
                setSelectedOrder(prevOrder => ({
                    ...prevOrder,
                    orderStatus: response.data.status || newStatus
                }));
                fetchOrders()
            }
        } catch (err) {
            console.error("Error updating order status:", err);
        }
    };



    if (loading) return <p>Loading orders...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <div className="col-md-2 add_button animated_back_button text-right">
                    <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                        handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back </ButtonStyle>
                </div>
                {!selectedOrder ? (
                    <div className="m-3">
                        <h2 className={styles.productName}>Manage Orders</h2>
                        <div className="table-responsive"> {/* Enables responsiveness */}
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Order Number</th>
                                        <th>Order Date</th>
                                        <th>Total Price</th>
                                        <th>Order Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order) => (
                                        <tr key={order.id}>
                                            <td>{order.orderNumber}</td>
                                            <td>{new Date(order.orderDate).toLocaleString()}</td>
                                            <td>₹{order.netTotalPrice}</td>
                                            <td>{order.orderStatus}</td>
                                            <td>
                                                <Button
                                                    className="secondary"
                                                    variant="secondary"
                                                    onClick={() => setSelectedOrder(order)}
                                                >
                                                    List
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <br />
                    </div>
                ) : (
                    <div className="container">
                        <Button
                            variant="danger"
                            className="popup-close-btn mb-5"
                            onClick={() => setSelectedOrder(null)}
                        >
                            &times;
                        </Button>

                        <div className="m-3">
                            <h3>
                                <strong>Name: </strong>
                                {selectedOrder.fullName}
                            </h3>
                            <p>
                                <strong>Delivery Address: </strong>
                                {selectedOrder.deliveryAddress}
                                <br />
                                {`${selectedOrder.city}, ${selectedOrder.pinCode}`}
                                <br />
                                {selectedOrder.state}
                            </p>
                            <h3>
                                <strong>Contact Number: </strong>
                                {selectedOrder.mobileNumber}
                            </h3>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 m-2">
                                <Form.Group className="d-flex justify-content-between align-items-center" controlId="orderStatus">
                                    <Form.Label><strong>Order Status:</strong></Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={orderStatus}
                                        onChange={(e) => handleStatusChange(e.target.value)}
                                        disabled={isDisabled}
                                    >
                                        <option value="PENDING">Pending</option>
                                        <option value="ORDER_OUT_FOR_DELIVERY">Out For Delivery</option>
                                        <option value="ORDER_DELIVERED">Order Delivered</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered mt-5">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder.orderItems.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.product.productName}</td>
                                            <td>{item.product.productCategory}</td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>₹{item.price}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="popup-buttons text-center mt-3">
                            <Button className="secondary" variant="secondary" onClick={() => setSelectedOrder(null)}>
                                Close
                            </Button>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default OrderList;
