import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styles from './productpage.module.css'
import { ButtonStyle } from "../UIElements/Buttons/ButtonStyle";
import authservice from "../Authentication/service/authservice";

const MyOrder = (props) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const userId = authservice.getCurrentUserid();
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/order/user/${userId}`);
                if (response.status === 200) {
                    setOrders(response.data);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    if (loading) return <p>Loading orders...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <div className="col-md-2 add_button animated_back_button text-right">
                    <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                        handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back </ButtonStyle>
                </div>
                {!selectedOrder ? (
                    <>
                        <h2 className={styles.productName}>Manage Orders</h2>
                        <p><strong>Note: If you have any issues or concerns with your order, you must report them by submitting a support request within 48 hours after your order has been delivered.</strong></p>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Mart Name</th>
                                        <th>Order Number</th>
                                        <th>Order Date</th>
                                        <th>Total Price</th>
                                        <th>Order Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order) => (
                                        <tr key={order.id}>
                                            <td>{order.mart.martName}</td>
                                            <td>{order.orderNumber}</td>
                                            <td>{new Date(order.orderDate).toLocaleString()}</td>
                                            <td>{order.netTotalPrice}</td>
                                            <td>{order.orderStatus}</td>
                                            <td>
                                                <Button className="secondary" variant="secondary" onClick={() => setSelectedOrder(order)}>
                                                    List
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table></div><br></br>
                    </>
                ) : (
                    <div>
                        <Button variant="danger" className="popup-close-btn mb-5" onClick={() => setSelectedOrder(null)}>
                            &times;
                        </Button>
                        <div>
                            <h3><strong>Name: </strong>{selectedOrder.fullName}</h3>
                            <p><strong>Delivery Address: </strong>{selectedOrder.deliveryAddress}<br></br>
                                {`${selectedOrder.city}, ${selectedOrder.pinCode}`}<br></br>
                                {selectedOrder.state}</p>
                            <h3><strong>Contact Number: </strong>{selectedOrder.mobileNumber}</h3>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered mt-5">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder.orderItems.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.product.productName}</td>
                                            <td>{item.product.productCategory}</td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>₹{item.price}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="popup-buttons">
                            <Button className="secondary text-center" variant="secondary" onClick={() => setSelectedOrder(null)}>
                                Close
                            </Button>
                        </div><br></br>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyOrder;
