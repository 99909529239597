import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Form, Button, Table } from "react-bootstrap";
import { TitleHeading } from "../UIElements/headings/TitleHeading";
import styles from '../listings/forms/addpost.module.css';
import style from '../UIElements/Form_label/FormLabels.module.css'
import sty from './martcard.module.css'
import LargeRectangle from "../advertisements/LargeRectangle";
import BillBoard from "../advertisements/BillBoard";
import { notifyUser, type } from '../utility/notifyUser';
import { ButtonStyle } from "../UIElements/Buttons/ButtonStyle";
import { FileInput } from "../UIElements/input/FileInput";
import { address, zipCode, personName, webSiteDomain } from '../RegxConst'

const ProductInventory = (props, selectedProduct) => {
    const { id } = useParams(); // Mart ID from URL
    const [showForm, setShowForm] = useState(false); // Toggle form visibility
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [productImages, setProductImage] = useState(null);
    const history = useHistory();
    const [webSite, setWebSite] = useState('');
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: null,
        martId: null,
        productName: "",
        productDescription: "",
        productCategory: "",
        sizeStockPrices: [{ size: "", stockQuantity: "", productPrice: 0 }],
        productImages: [],
        imageFields: [""]   // Stores input fields
    });

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/get-by-mart/${id}`);
            if (response.status === 200) {
                setProducts(response.data);
            } else {
                alert("Failed to fetch product data.");
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProducts();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSizeChange = (index, field, value) => {
        const updatedSizes = [...formData.sizeStockPrices];
        updatedSizes[index][field] = value;
        setFormData({ ...formData, sizeStockPrices: updatedSizes });
    };

    const areAllSizesFilled = () => {
        return formData.sizeStockPrices.every(entry =>
            entry.size.trim() !== "" &&
            entry.stockQuantity !== "" &&
            entry.productPrice !== 0
        );
    };

    const addSizeEntry = () => {
        if (areAllSizesFilled()) {
            setFormData({
                ...formData,
                sizeStockPrices: [...formData.sizeStockPrices, { size: "", stockQuantity: "", productPrice: 0 }]
            });
        }
    };

    const handleImageUpload = async (file, index) => {
        if (!file || !file.type.startsWith("image")) {
            notifyUser("Invalid File", "Please select a valid image file", "warning");
            return;
        }

        const formDataUpload = new FormData();
        formDataUpload.append("photo", file);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/abmgr/user/upload-pan-card`,
                formDataUpload
            );

            if (response.status === 200 && response.data) {
                const uploadedImageName = response.data; // Get the new image name

                setFormData((prev) => {
                    const updatedImages = [...prev.productImages];
                    updatedImages[index] = {
                        name: uploadedImageName,
                        url: `${process.env.REACT_APP_IMAGES_URL}/${uploadedImageName}`
                    };

                    return { ...prev, productImages: updatedImages };
                });

                // **Force refresh product data from backend**
                fetchProducts();

                notifyUser("Success", "Image uploaded successfully", "success");
            } else {
                notifyUser("Error", "Invalid image name received", "danger");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            notifyUser("Error", "Failed to upload image", "danger");
        }
    };


    const addImageField = () => {
        setFormData((prev) => ({
            ...prev,
            productImages: [
                ...prev.productImages,
                { name: "", url: "" }
            ]
        }));
    };

    const removeImage = (index) => {
        setFormData((prev) => {
            const updatedImages = prev.productImages.filter((_, i) => i !== index);

            return {
                ...prev,
                productImages: updatedImages
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const ProductDTO = {
            ...formData,
            martId: Number(id),
            id: currentProduct ? currentProduct.id : null,
            sizeStockPrices: formData.sizeStockPrices.map(entry => ({
                size: entry.size.trim(),
                stockQuantity: Number(entry.stockQuantity),
                productPrice: entry.productPrice
            })),
            productImages: formData.productImages.length > 0
                ? formData.productImages.map(entry => entry.name)
                : []
        };

        try {
            if (currentProduct) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/update/${currentProduct.id}`, ProductDTO);
                notifyUser("Success", "Product updated successfully", "success");
                setShowForm(false);
                await fetchProducts();
            } else {
                if (formData.productImages.length === 0) {
                    notifyUser("Error", "Please Add Product Image", "warning");
                } else {
                    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/add`, ProductDTO);
                    notifyUser("Success", "Product added successfully", "success");
                    setShowForm(false);
                    fetchProducts();
                }
            }
        } catch (error) {
            console.error("Error updating product:", error);
            notifyUser("Error", "Failed to update product", "danger");
        }
    };

    const handleProductMart = async (product = null) => {
        setCurrentProduct(product);
        setShowForm(true);

        if (product) {
            await fetchProducts();

            setFormData({
                productName: product.productName || '',
                productDescription: product.productDescription || '',
                productCategory: product.productCategory || '',
                sizeStockPrices: (product.sizeStockPrices && product.sizeStockPrices.length > 0)
                    ? product.sizeStockPrices.map(entry => ({
                        size: entry.size.trim(),
                        stockQuantity: Number(entry.stockQuantity),
                        productPrice: entry.productPrice
                    }))
                    : [{ size: "", stockQuantity: "", productPrice: 0 }],

                productImages: (product.productImages && product.productImages.length > 0)
                    ? product.productImages.map(img => ({
                        name: img,
                        url: process.env.REACT_APP_IMAGES_URL + '/' + img
                    }))
                    : [],
                webSite: product.webSite
            });
        } else {
            setFormData({
                productName: '',
                productDescription: '',
                productCategory: '',
                sizeStockPrices: [{ size: "", stockQuantity: "", productPrice: 0 }],
                productImages: [],
                webSite: '',
            });
        }
    };



    const handleDelete = async (productId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/remove/${productId}`);
            notifyUser("Success", "Product deleted successfully", "success");

            setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
        } catch (error) {
            console.error("Error deleting product:", error);
            notifyUser("Error", "Failed to delete product", "danger");
        }
    };

    const handleRemoveSizeEntry = (index) => {
        const updatedSizes = formData.sizeStockPrices.filter((_, i) => i !== index);
        setFormData({ ...formData, sizeStockPrices: updatedSizes });
    };

    const handleWebLink = (value) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            webSite: webSiteDomain.test(value) || value === '' ? '' : 'Website is not valid!',
        }));

        setWebSite(value);
    };

    return (
        <div className="admin-view-head">
            <div className="mt-4 g-2 col-md-12">
                <div className="col-md-2 add_button animated_back_button text-right">
                    <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                        handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back </ButtonStyle>
                </div>
                <TitleHeading text='Inventry Management' />
                {!showForm ? (
                    <Button className="secondary" variant="primary" onClick={() => handleProductMart(null)}>Add New Product</Button>
                ) : (
                    <div>
                        <Button variant="danger" className="popup-close-btn" onClick={() => setShowForm(false)}>
                            &times;
                        </Button>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Product Name*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control type="text" name="productName" value={formData.productName} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label>Description*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control as="textarea" name="productDescription" value={formData.productDescription} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}> <Form.Label>Category*</Form.Label></div>
                                    <div className='col-md-10'>
                                        <Form.Control type="text" name="productCategory" value={formData.productCategory} onChange={handleInputChange} required />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}> <Form.Label>Size, Stock & Price*</Form.Label></div>
                                    <div className='col-md-10'>
                                        {formData.sizeStockPrices.map((entry, index) => (
                                            <div key={index}>
                                                <Form.Control type="text" placeholder="Size" value={entry.size} onChange={(e) => handleSizeChange(index, "size", e.target.value)} required />
                                                <Form.Control type="number" placeholder="Stock" value={entry.stockQuantity} onChange={(e) => handleSizeChange(index, "stockQuantity", e.target.value)} required />
                                                <Form.Control type="number" placeholder="Price" value={entry.productPrice} onChange={(e) => handleSizeChange(index, "productPrice", e.target.value)} required />

                                                <Button
                                                    variant="danger"
                                                    className={`p-2 ${sty.addbtn}`}
                                                    onClick={() => handleRemoveSizeEntry(index)}
                                                >
                                                    🗑️
                                                </Button>
                                            </div>
                                        ))}
                                        <Button
                                            variant="secondary"
                                            onClick={addSizeEntry}
                                            disabled={!areAllSizesFilled()}
                                            className={`mt-2 ${sty.addbtn}`}
                                        >
                                            Add More Sizes
                                        </Button></div>
                                </div>

                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}> <Form.Label>Product Images*</Form.Label></div>
                                    <div className='col-md-10 justify-content-center'>

                                        {formData.productImages.map((imageObj, index) => (
                                            <>
                                                <div key={index} style={{ position: "relative", marginBottom: "10px", display: "flex", }}>
                                                    <FileInput
                                                        file_url={imageObj.url || ""}
                                                        file={null} // Prevent default file input from being pre-filled
                                                        file_name={imageObj.name || ""}
                                                        disabled={false}
                                                        onFileSelect={(e) => handleImageUpload(e.target.files[0], index)}
                                                        fileFormats=".png, .jpg, .jpeg, .gif"
                                                        labelText={'Choose Image'}
                                                    />
                                                </div>
                                                {imageObj.name && (
                                                    <div className={sty.image_container}>
                                                        <img src={`${process.env.REACT_APP_IMAGES_URL}/${imageObj.name}`} alt="Product Image" className={sty.preview_image} />
                                                        {index === formData.productImages.length - 1 && (<button className={sty.cancel_btn} onClick={() => removeImage(index)}>&times;</button>)}
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}></div>
                                    <div className="col-md-10">
                                        <Button
                                            variant="secondary"
                                            onClick={addImageField}
                                            className={`mt-2 ${sty.addbtn}`}
                                            disabled={formData.productImages.length >= 5} // Limit to 5 images
                                        >
                                            Add Photo
                                        </Button>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <div className='row col-md-10 justify-content-center p-1 ml-4'>
                                    <div className={`${style.addpost_label} col-md-2 text-md-right text-left`}><Form.Label> Photo Detail </Form.Label></div>
                                    <div className="col-md-10">
                                        <input type="text" className={`form-control`}
                                            placeholder={'Enter URL'}
                                            name="webSite" value={webSite}
                                            // onFocus={() => this.setState({ showWWW: true })}
                                            onChange={(event) => handleWebLink(event.target.value)}
                                            noValidate />
                                        {/* {(this.state.showWWW || this.state.webSite.length > 0) ? <label className={`${styles.website_prefix_label}`}></label> : null} */}
                                        <p>{errors.webSite}</p>
                                    </div>
                                </div>
                            </Form.Group>
                            <div className="popup-buttons">
                                <Button className="secondary" variant="danger" onClick={() => setShowForm(false)}>Cancel</Button>
                                <Button className="secondary" variant="primary" type="submit">Submit</Button>
                            </div>
                        </Form>
                    </div>
                )}
                {!showForm && (
                    <div className="table-responsive"> {/* Enables responsiveness */}
                        <Table striped bordered hover className="mt-4">
                            <thead>
                                <tr>
                                    <th>S.no.</th>
                                    <th>Product Name</th>
                                    <th>Category</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{product.productName}</td>
                                        <td>{product.productCategory}</td>
                                        <td>{product.productDescription}</td>
                                        <td>{product.status}</td>
                                        <td>
                                            <Button variant="warning" onClick={() => handleProductMart(product)}>
                                                ✏️
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="delete-icon"
                                                variant="warning"
                                                onClick={() => handleDelete(product.id)}
                                            >
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div><br></br>
            <div className={`listings row ${styles.large_rect}`}>
                {!props.isAdmin && <LargeRectangle spot={"-1"} />}
            </div>
            <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                {!props.isAdmin && <BillBoard spot={"-1"} />}
            </div>
        </div>
    );
};

export default ProductInventory;
