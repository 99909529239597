import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import styles from './productpage.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import authservice from '../Authentication/service/authservice';
import { ButtonStyle } from "../UIElements/Buttons/ButtonStyle";
import Modal from "react-modal";
import { Button } from 'react-bootstrap';
import mart from '../../assets/mart.png';
import TextDecoration from '../utility/TextDecoration';

const ProductPage = (props) => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});
  const [currentIndex, setCurrentIndex] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [martDetails, setMartDetails] = useState(null);
  const [martStatus, setMartStatus] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [cartItems, setCartItems] = useState([]);

  const [showCartModal, setShowCartModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [pendingProduct, setPendingProduct] = useState(null);
  const [cartMartId, setCartMartId] = useState(localStorage.getItem("cartMartId"));
  const [cartClearing, setCartClearing] = useState(false);

  const fetchMartDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/get-mart/${id}`
      );
      setMartDetails(response.data);
      setModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching mart details:", error);
    }
  };

  const fetchMartStatusDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/get-mart/${id}`
      );
      setMartStatus(response.data.open);
    } catch (error) {
      console.error("Error fetching mart details:", error);
    }
  };

  useEffect(() => {
    const initialIndex = {};
    products.forEach((product) => {
      initialIndex[product.id] = 0;
    });
    setCurrentIndex(initialIndex);
  }, [products]);

  const handleNext = (productId, totalImages) => {
    setCurrentIndex((prev) => ({
      ...prev,
      [productId]: (prev[productId] + 1) % totalImages,
    }));
  };

  const handlePrev = (productId, totalImages) => {
    setCurrentIndex((prev) => ({
      ...prev,
      [productId]: (prev[productId] - 1 + totalImages) % totalImages,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => {
        const newIndexes = { ...prev };
        products.forEach((product) => {
          const totalImages = product.productImages.length;
          if (totalImages > 1) {
            newIndexes[product.id] = (prev[product.id] + 1) % totalImages;
          }
        });
        return newIndexes;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [products]);

  const handleClearCart = async () => {
    setShowCartModal(false);
    setCartClearing(true);
    setPendingProduct(null);

    const userId = authservice.getCurrentUserid();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}?paymentMode=PHONEPE`
      );

      if (response.data && response.data.items.length > 0) {
        await Promise.all(
          response.data.items.map(item =>
            axios.delete(
              `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/clear-shopping-bag/${userId}`
            )
          )
        );
      }

      localStorage.removeItem("cart");
      localStorage.setItem("cartMartId", id);
      setCart({});
      setCartMartId(id);

      setTimeout(() => {
        setCartClearing(false);
        if (pendingProduct) {
          //addToCart(pendingProduct.product);
          setPendingProduct(null);
        }
      }, 300);
    } catch (error) {
      console.error("❌ Error clearing cart:", error);
      setCartClearing(false);
    }
  };

  const selectedProducts = useMemo(() => {
    return selectedCategory ? products.filter(p => p.productCategory === selectedCategory) : [];
  }, [selectedCategory, products]);

  const remainingProducts = useMemo(() => {
    return selectedCategory ? products.filter(p => p.productCategory !== selectedCategory) : products;
  }, [products]);


  useEffect(() => {
    fetchMartStatusDetails()
    fetchProducts();
    fetchCart();
    window.scroll(0, 0)
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/get-published-products/${id}`);
      const formattedProducts = response.data.flatMap(product =>
        product.sizeStockPrices.map(sizeData => ({
          ...product,
          size: sizeData.size,
          price: sizeData.productPrice, // Corrected price property
          remainingStock: sizeData.stockQuantity, // Corrected stock property
          productImage: product.productImages[0] || "default-image.jpg"
        }))
      );
      setProducts(formattedProducts);
      const uniqueCategories = [...new Set(response.data.map(p => p.productCategory))];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCart = async () => {
    const userId = authservice.getCurrentUserid();
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}?paymentMode=PHONEPE`);
      setCartItems(response.data.items || []);
      const cartData = {};

      response.data.items.forEach((item) => {
        //const productKey = `${item.size}-${item.productId}`;
        cartData[item.size + item.productId] = item.quantity;
      });
      setCart(cartData);
      localStorage.setItem("cart", JSON.stringify(cartData)); // Sync local storage
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const handleAddToCart = async (product) => {
    if (showCartModal) return;
    const userId = authservice.getCurrentUserid();
    const currentMartId = id;

    const quantity = 1;
    if (authservice.getCurrentUserid() === null)
      setShowLoginModal(true)
    else {
      if (cartMartId && cartMartId !== currentMartId) {
        setPendingProduct({ product, size: product.size, quantity });
        setShowCartModal(true);
        return;
      }
    }
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products`,
      null,
      {
        params: { productId: product.id, size: product.size, quantity, paymentMode: "PHONEPE" },
      }).then(() => {
        setCart((prevCart) => {
          const updatedCart = { ...prevCart, [product.size + product.id]: quantity }
          localStorage.setItem("cart", JSON.stringify(updatedCart));
          localStorage.setItem("cartMartId", currentMartId);
          setCartMartId(currentMartId);
          return updatedCart;
        });

        fetchCart();
      }).catch((error) => console.error("Error adding to cart:", error))
  };

  const handleIncreaseQuantity = async (product) => {
    console.log("Product object:", product);
    const userId = authservice.getCurrentUserid();
    const currentMartId = id;

    if (!product.id || !product.size) {
      console.error("Error: productId or size is missing!", product);
      return;
    }

    setCart((prevCart) => {
      const currentQuantity = prevCart[product.size + product.id] || 0;
      const newQuantity = currentQuantity + 1;

      const updatedCart = { ...prevCart, [product.size + product.id]: newQuantity };
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      localStorage.setItem("cartMartId", currentMartId);
      setCartMartId(currentMartId);

      return updatedCart;
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products`,
        null, // No body, only params
        {
          params: {
            productId: product.id,
            size: product.size,
            quantity: cart[product.size + product.id] + 1 || 1, // Ensure correct quantity
            paymentMode: "PHONEPE"
          },
          headers: { "Content-Type": "application/json" },
        }
      );
      // fetchCart(); // Refresh cart after successful API request
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };

  const handleDecresaeQuantity = async (product) => {
    console.log("Product object:", product);
    const userId = authservice.getCurrentUserid();
    const currentMartId = id;

    if (!product.id || !product.size) {
      console.error("Error: productId or size is missing!", product);
      return;
    }

    setCart((prevCart) => {
      const currentQuantity = prevCart[product.size + product.id] || 0;
      const newQuantity = currentQuantity - 1;

      const updatedCart = { ...prevCart, [product.size + product.id]: newQuantity };
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      localStorage.setItem("cartMartId", currentMartId);
      setCartMartId(currentMartId);

      return updatedCart;
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/abmgr/mart/product/shopping-bag/${userId}/products`,
        null, // No body, only params
        {
          params: {
            productId: product.id,
            size: product.size,
            quantity: cart[product.size + product.id] - 1 || 0,
            paymentMode: "PHONEPE"
          },
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };


  return (
    <div className="admin-view-head">
      <div className="col-md-2 add_button animated_back_button text-right m-4">
        <ButtonStyle type="button" className="form-control btn btn-block btn_font"
          handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp; Go Back </ButtonStyle>
      </div>
      {martStatus === false ? (
        <div className={styles.martClosedContainer}>
          <img
            src={mart} // Replace with actual path
            alt="Mart Closed"
            className={styles.martClosedGif}
          />
        </div>
      ) : (
        <>
          <Modal isOpen={showCartModal} onRequestClose={() => setShowCartModal(false)}
            className={styles.modalContent} overlayClassName={styles.modalOverlay}>
            <div className={styles.martDetails}>
              <h3 className={styles.product_name}>Clear Cart?</h3>
              <p><strong>You already have products from a different mart. Do you want to clear your cart and add this product?</strong></p>
              <div className={styles.modalButtons}>
                <Button variant="danger" className="secondary" onClick={handleClearCart} disabled={cartClearing}>
                  {cartClearing ? "Clearing..." : "Yes, Clear Cart"}
                </Button>&nbsp;
                <Button className="secondary" variant="secondary" onClick={() => setShowCartModal(false)}>No</Button>
              </div>
            </div>
          </Modal>
          <Modal isOpen={showLoginModal} onRequestClose={() => showLoginModal(false)}
            className={styles.modalContent} overlayClassName={styles.modalOverlay}>
            <div className={styles.martDetails}>
              <h3 className={styles.product_name}>Add To Cart?</h3>
              <p><strong>To add this product in the cart, you have to <Link to="/registration">register</Link> or <Link to="/login/user">login</Link>  your account.</strong></p>
              <div className={styles.modalButtons}>
                <Button className="secondary" variant="secondary" onClick={() => setShowLoginModal(false)}>No</Button>
              </div>
            </div>
          </Modal>
          <div className={`${styles.cartButtonContainer} m-5`}>
            <ButtonStyle type="button" className="form-control btn btn-block btn_font">
              <Link to="/mart/product/cart">🛒 Go to Cart</Link>
            </ButtonStyle>
            <span className={styles.infoIcon} onClick={fetchMartDetails}>
              i
            </span>
          </div>
          {/* <div className={`${styles.container}`}> */}
            <h2 className={styles.heading}>🛍️ Products</h2>

            <div className={`col-md-12 col-12 col-sm-12 ${styles.filterContainer}`}>
              <label className={styles.filterLabel}>Select Category:</label>
              <div className={styles.dropdown} onClick={() => setDropdownOpen(!dropdownOpen)}>
                <div className={styles.dropdownSelected}>
                  {selectedCategory || "All Categories"}
                </div>
                {dropdownOpen && (
                  <ul className={styles.dropdownList}>
                    <li
                      className={styles.dropdownItem}
                      onClick={() => { setSelectedCategory(""); setDropdownOpen(false); }}
                    >
                      All
                    </li>
                    {categories.map((category, index) => (
                      <li
                        key={index}
                        className={`${styles.dropdownItem} ${selectedCategory === category ? styles.active : ""}`}
                        onClick={() => { setSelectedCategory(category); setDropdownOpen(false); }}
                      >
                        {category}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>


            {selectedProducts.length > 0 && (
              <>
                <h3 className={styles.subheading}>Selected Category</h3>
                <div className="container-fluid">
                  <div className="row">
                    {selectedProducts.map((product) => {
                      const cartItem = cartItems.find((item) => item.productId === product.productId && item.size === product.size);
                      return (
                        <div key={`${product.productId}-${product.size}`} className="col-6 col-md-4 col-lg-2">
                          <div className={`${styles.card}`}>
                            <div className={styles.carousel_container}>
                              {product.productImages.length > 1 && (
                                <>
                                  <button className={styles.prev_btn} onClick={() => handlePrev(product.id, product.productImages.length)}>
                                    <a onClick={(e) => { e.preventDefault() }} className={styles.previous_round}>&#8249;</a>
                                  </button>
                                  <button className={styles.next_btn} onClick={() => handleNext(product.id, product.productImages.length)}>
                                    <a onClick={(e) => { e.preventDefault() }} className={styles.next_round}>&#8250;</a>
                                  </button>
                                </>
                              )}
                              <img
                                src={`${process.env.REACT_APP_IMAGES_URL}${product.productImages[currentIndex[product.id] || 0]}`}
                                alt="Product"
                                className={styles.product_img}
                              />
                            </div>
                            <h3 className={styles.product_name}>{product.productName}</h3>
                            <p className={styles.description}>{TextDecoration.ellipsize(product.productDescription, 20)}</p>
                            <p className={styles.stock}>Size: {product.size}<br></br>
                              Stock: {product.remainingStock}</p>
                            <p className={styles.price}>Price: ₹{product.price}</p>
                            {cart[product.size + product.id] ? (
                              <div className={styles.quantity_container}>
                                <button className={styles.quantity_btn} onClick={() => handleDecresaeQuantity(product)}>
                                  -
                                </button>
                                <span className={styles.quantity}>{cart[product.size + product.id]}</span>
                                <button className={styles.quantity_btn} onClick={() => handleIncreaseQuantity(product)}>
                                  +
                                </button>
                              </div>
                            ) : (
                              <button className={styles.add_to_cart} onClick={() => handleAddToCart(product)}>
                                Add to Cart
                              </button>
                            )}
                          </div>
                        </div>)
                    })}
                  </div>
                </div>
              </>
            )}

            <h3 className={styles.subheading}>All Products</h3>
            {/* <div> */}
              <div className="row">
                {remainingProducts.map((product) => {
                  const cartItem = cartItems.find((item) => item.productId === product.productId && item.size === product.size);
                  return (
                    <div key={`${product.productId}-${product.size}`} className="col-6 col-md-4 col-lg-2">
                      <div className={`w-100 ${styles.card}`}>
                        <div className={`${styles.carousel_container}`}>
                          {product.productImages.length > 1 && (
                            <>
                              <button className={styles.prev_btn} onClick={() => handlePrev(product.id, product.productImages.length)}>
                                <a onClick={(e) => { e.preventDefault() }} className={styles.previous_round}>&#8249;</a>
                              </button>
                              <button className={styles.next_btn} onClick={() => handleNext(product.id, product.productImages.length)}>
                                <a onClick={(e) => { e.preventDefault() }} className={styles.next_round}>&#8250;</a>
                              </button>
                            </>
                          )}
                          <img
                            src={`${process.env.REACT_APP_IMAGES_URL}${product.productImages[currentIndex[product.id] || 0]}`}
                            alt="Product"
                            className={styles.product_img}
                          />
                        </div>
                        <h3 className={styles.product_name}>{product.productName}</h3>
                        <p className={styles.description}>{TextDecoration.ellipsize(product.productDescription, 60)}</p>
                        <p className={styles.stock}>Size: {product.size}<br></br>
                          Stock: {product.remainingStock}</p>
                        <p className={styles.price}>Price: ₹{product.price}</p>
                        {cart[product.size + product.id] ? (
                          <div className={styles.quantity_container}>
                            <button className={styles.quantity_btn} onClick={() => handleDecresaeQuantity(product)}>
                              -
                            </button>
                            <span className={styles.quantity}>{cart[product.size + product.id]}</span>
                            <button className={styles.quantity_btn} onClick={() => handleIncreaseQuantity(product)}>
                              +
                            </button>
                          </div>
                        ) : (
                          <button className={styles.add_to_cart} onClick={() => handleAddToCart(product)}>
                            Add to Cart
                          </button>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            {/* </div> */}

          {/* </div> */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            className={styles.modalContent}
            overlayClassName={styles.modalOverlay}
          >
            <Button variant="danger" className="popup-close-btn" onClick={() => setModalIsOpen(false)}>
              &times;
            </Button>
            {martDetails && (
              <div className={styles.martDetails}>
                <img
                  src={`${process.env.REACT_APP_IMAGES_URL}${martDetails.thumbnailImage}`}
                  alt="Mart Thumbnail"
                  className={styles.martImage}
                />
                <h2 className={styles.product_name}>{martDetails.martName}</h2>
                <p>{martDetails.businessDescription}</p>
                <p><strong>Address:</strong> {martDetails.businessAddress}</p>
                <p><strong>Contact Person:</strong> {martDetails.contactName}</p>
                <p><strong>Contact Number:</strong> {martDetails.contactNumber}</p>
                <p><strong>Home Delivery: </strong> {martDetails.homeDeliveryAvailable ? 'Yes' : 'No'} </p>
                <p><strong>Pickup Available: </strong> {martDetails.pickupAvailable ? 'Yes' : 'No'} </p>
                <p><strong>Mart Open: </strong> {martDetails.open ? 'Yes' : 'No'} </p>
                <Button className="secondary" variant="secondary" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </div>
            )}
          </Modal>
        </>
      )}<br></br>
    </div>
  );
};

export default ProductPage;
